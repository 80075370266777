import React from "react";
import { Link } from "gatsby";
import { useTheme } from "../ThemeContext";
import routes from "../../routes";

const footerLinks = [
  { to: routes.dmca, text: "DMCA Policy" },
  { to: routes.privacyPolicy, text: "Privacy Policy" },
  { to: routes.affiliateDisclaimer, text: "Affiliate Disclaimer" },
  { href: "/sitemap.xml", text: "Sitemap" },
];

export default function FooterCopyrightLegal() {
  const {
    theme: { title },
  } = useTheme();

  return (
    <div className="w-full flex flex-wrap pt-4 md:pt-8 text-sm">
      <div className="flex-initial">
        Copyright &copy; {new Date().getFullYear()} {title}
      </div>
      <div className="flex-1">
        <ul className="flex justify-end">
          {footerLinks.map(({ to, href, text }) => (
            <li key={text} className="flex-initial md:w-auto mx-2">
              {to ? (
                <Link className="transition hover:text-primary-dark" to={to}>
                  {text}
                </Link>
              ) : (
                <a className="transition hover:text-primary-dark" href={href}>
                  {text}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
