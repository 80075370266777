import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import routes from "../../routes";
import { useLogo } from "../../hooks/useLogo";
// import { useSites } from "../../hooks/useSites";
import { useCategories } from "../../hooks/useCategories";
import { useTheme } from "../ThemeContext";
import FooterCopyrightLegal from "./FooterCopyrightLegal";
import FooterLink from "./FooterLink";

const navLinks = [
  { slug: routes.home, text: "Home" },
  { slug: `${routes.popular}/`, text: "Popular" },
  { slug: `${routes.blog}/`, text: "Blog" },
  { slug: `${routes.about}/`, text: "About" },
];
const sites = [
  { url: "https://thetopmusicalinstruments.com", title: "Top Musical Instruments" },
  { url: "https://justtreesonline.com", title: "Just Trees" },
];

export default function Footer() {
  const logo = useLogo();
  const {
    theme: { title, url },
  } = useTheme();
  const categories = useCategories();

  return (
    <footer itemType="http://schema.org/WPFooter" className="bg-gray-300 flex py-4 lg:py-8">
      <div className="container mx-auto px-4 md:px-8">
        <div className="w-full flex flex-wrap">
          <div className="flex-initial w-full md:w-1/2 pb-4">
            <div className="w-40  pb-4 md:pb-8">
              <Link to={routes.home} title={title}>
                <Img fluid={logo} />
              </Link>
            </div>
            <p className="md:max-w-sm text-gray-800 text-sm">
              This webpage may contain links to products or other sites. Please assume all such links are{" "}
              <Link to={routes.affiliateDisclaimer}>affiliate links</Link> which may result revenue for {url}.
            </p>
          </div>
          <div className="flex-initial w-full md:w-1/2 pb-4">
            <div className="flex flex-wrap md:justify-end">
              <div className="w-full md:w-auto md:flex-shrink pb-2">
                <h3 className="font-bold mb-3">Top Pages</h3>
                <ul>
                  {navLinks.map(({ slug, text }) => (
                    <FooterLink key={slug} slug={slug} text={text} />
                  ))}
                </ul>
              </div>
              <div className="md:ml-8 lg:ml-20 w-full md:w-auto md:flex-shrink pb-2">
                <h3 className="font-bold mb-3">Categories</h3>
                <ul>
                  {categories.map(({ name, slug }) => (
                    <FooterLink key={slug} slug={`${routes.blog}/${slug}/`} text={name} />
                  ))}
                </ul>
              </div>
              <div className="md:ml-8 lg:ml-20 w-full md:w-auto md:flex-shrink pb-2">
                <h3 className="font-bold mb-3">Other Links</h3>
                <ul>
                  <FooterLink slug={`${routes.contact}/`} text="Contact Us" />
                  <FooterLink href="https://zeyventures.com" text="Business Inquiries" />
                </ul>
                <ul>
                  {sites.map(({ url, title }) => (
                    <FooterLink key={url} href={url} text={title} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <FooterCopyrightLegal />
      </div>
    </footer>
  );
}
