import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { useState } from "react";
import routes from "../routes";
import { useLogo } from "../hooks/useLogo";
import { useTheme } from "./ThemeContext";

const navLinks = [
  { slug: routes.home, text: "Home" },
  { slug: `${routes.popular}/`, text: "Popular" },
  { slug: `${routes.blog}/`, text: "Blog" },
  { slug: `${routes.about}/`, text: "About" },
];

const barStyle = {
  margin: "6px 0",
  width: "44px",
  height: "5px",
};

export default function Nav() {
  const logo = useLogo();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const {
    theme: { title },
  } = useTheme();

  return (
    <>
      <nav className="flex items-center justify-between px-4 py-2 border border-b border-gray-200 shadow md:hidden">
        <div className="w-32 max-w-xs">
          <Link to={routes.home} title={title}>
            <Img fluid={logo} />
          </Link>
        </div>
        <button name="mobile menu" type="button" className="px-2 py-2" onClick={() => setMobileMenuOpen(state => !state)}>
          <span className="sr-only">Mobile Menu</span>
          <div className="bg-gray-400" style={barStyle}></div>
          <div className="bg-gray-400" style={barStyle}></div>
          <div className="bg-gray-400" style={barStyle}></div>
        </button>
      </nav>
      <nav className="hidden w-full border border-b-2 border-gray-300 md:block">
        <div className="container flex items-center p-2 mx-auto md:p-4 md:px-8">
          <div className="w-1/3 max-w-xs md:w-40 md:pr-4">
            <Link to={routes.home} title={title}>
              <Img fluid={logo} />
            </Link>
          </div>
          <ul className="flex items-center justify-end flex-initial text-xs font-bold uppercase lg:pl-4 lg:flex-auto md:text-lg">
            {navLinks.map(({ slug, text }) => (
              <li itemType="http://schema.org/SiteNavigationElement" key={slug} className="py-1 mx-1 mb-0 md:mx-2 lg:mx-4">
                <Link className="transition hover:text-primary-dark" to={slug}>
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      {mobileMenuOpen && (
        <>
          <div className="fixed top-0 bottom-0 left-0 z-50 w-8/12 min-h-screen overflow-y-scroll text-white shadow bg-primary">
            <div className="relative w-full py-4">
              <ul>
                {navLinks.map(({ slug, text }) => (
                  <li itemType="http://schema.org/SiteNavigationElement" key={slug} className="p-4 text-xl">
                    <Link className="transition hover:text-primary-dark" to={slug}>
                      {text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <button
            name="mobile menu"
            type="button"
            onClick={() => setMobileMenuOpen(false)}
            className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-gray-800 opacity-50"
          />
        </>
      )}
    </>
  );
}
