// used during build with nodejs. cannot be export default
// eslint-disable-next-line
module.exports = {
  home: "/",
  blog: "/blog",
  popular: "/popular",
  about: "/about",
  affiliateDisclaimer: "/affiliate-disclaimer/",
  contact: "/contact",
  dmca: "/dmca/",
  privacyPolicy: "/privacy-policy/",
};
