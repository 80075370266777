import React from "react";
import { Link } from "gatsby";

// eslint-disable-next-line
export default function FooterLink({ slug, href, text }) {
  return (
    <li key={href} className="mb-1">
      {href ? (
        <a className="transition hover:text-primary-dark py-1 inline-block" href={href} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ) : (
        <Link className="transition hover:text-primary-dark py-1 inline-block" to={slug}>
          {text}
        </Link>
      )}
    </li>
  );
}
