import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Nav from "./Nav";
import Footer from "./FooterComponents/Footer";

import "./wysiwig.css";
import "../tailwind.css";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        {/* start: automatically links up all affiliate links in commission junction */}
        {/* <script src="//www.anrdoezrs.net/am/9236889/include/allCj/impressions/page/am.js" /> */}
        {/* end: automatically links up all affiliate links in commission junction */}
      </Helmet>
      <Nav />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Layout;
