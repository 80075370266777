import { useStaticQuery, graphql } from "gatsby";

export const useMetaImage = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "images/common/meta-image.jpg" }) {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    `
  );

  const fluid = file && file.childImageSharp && file.childImageSharp.fluid;

  if (!fluid) {
    throw new Error("The theme you are trying to use probably does not have a correct image at common/meta-image.jpg");
  }

  return fluid;
};
