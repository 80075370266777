import { useStaticQuery, graphql } from "gatsby";

export const useLogo = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "images/common/logo.png" }) {
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  const fluidLogo = file && file.childImageSharp && file.childImageSharp.fluid;

  if (!fluidLogo) {
    throw new Error("The theme you are trying to use probably does not have a correct image at common/logo.png");
  }

  return fluidLogo;
};
