import { useStaticQuery, graphql } from "gatsby";

export const useCategories = () => {
  const { allCategory } = useStaticQuery(
    graphql`
      {
        allCategory {
          edges {
            node {
              slug
              name
            }
          }
        }
      }
    `
  );

  const categories = allCategory && allCategory.edges;

  if (!categories || categories.length === 0) {
    throw new Error("The theme you are using has no or zero categories");
  }

  return categories.map(({ node: { name, slug } }) => ({ name, slug }));
};
